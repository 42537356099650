import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import LoaderWrapper from "../../../../components/LoaderWrapper/LoaderWrapper";
import {
  FlexStartRow,
  Info,
  Table,
  TableHeader,
  TableRow,
  TablesContainer,
  Title,
} from "../../../../components/ReusableStyles";
import { getMainnetMetrics, MainnetMetricsType } from "../../../../data/services/getMainnetMetrics";
import { formatPiValues } from "../../../../utils/formatPiValues";
import * as S from "./MainnetMetrics.styles";

const MainnetMetrics = () => {
  const [metrics, setMetrics] = useState<MainnetMetricsType | null>();
  const [fetchingError, setFetchingError] = useState<{ title: string; error: any } | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    setIsLoading(true);

    (async () => {
      try {
        const fetchedMetrics = await getMainnetMetrics();

        setMetrics(fetchedMetrics);
      } catch (error: any) {
        setFetchingError({ title: t("fetching.error"), error });
      } finally {
        setIsLoading(false);
      }
    })();
  }, [t]);

  const unlocked_circulating_supply =
    metrics?.total_circulating_supply && metrics?.total_locked
      ? metrics.total_circulating_supply - metrics.total_locked
      : null;

  return (
    <S.Container>
      <TableHeader>
        <FlexStartRow>
          <Title>
            {t("mainnet")} {t("metrics")}
          </Title>
        </FlexStartRow>
      </TableHeader>
      <LoaderWrapper loading={!metrics || isLoading} containerHeight={23} fetchingError={fetchingError}>
        <TablesContainer>
          <Table>
            <TableRow>
              <Info>{t("total.migrated.mining.rewards")}</Info>
              <Info $textOnRight>
                {metrics?.total_circulating_supply
                  ? `${formatPiValues(metrics.total_circulating_supply)} π`
                  : "n/a"}
              </Info>
            </TableRow>
            <TableRow>
              <Info>{`  ${t("currently.locked.mining.rewards")}`}</Info>
              <Info $textOnRight>
                {metrics?.total_locked ? `${formatPiValues(metrics.total_locked)} π` : "n/a"}
              </Info>
            </TableRow>
            <TableRow>
              <Info>{`  ${t("unlocked.mining.rewards")}`}</Info>
              <Info $textOnRight>
                {unlocked_circulating_supply ? `${formatPiValues(unlocked_circulating_supply)} π` : "n/a"}
              </Info>
            </TableRow>
            <TableRow>
              <Info>{t("circulating.supply")}</Info>
              <Info $textOnRight>
                {metrics?.total_circulating_supply
                  ? `${formatPiValues(metrics.total_circulating_supply)} π`
                  : "n/a"}
              </Info>
            </TableRow>
            <TableRow>
              <Info>{t("total.supply")}</Info>
              <Info $textOnRight>
                {metrics?.total_supply ? `${formatPiValues(metrics.total_supply)} π` : "n/a"}
              </Info>
            </TableRow>
            <TableRow $last>
              <Info>{t("max.supply")}</Info>
              <Info $textOnRight>{formatPiValues(100000000000)} π</Info>
            </TableRow>
          </Table>
        </TablesContainer>
      </LoaderWrapper>
    </S.Container>
  );
};

export default MainnetMetrics;
