import { restClient } from "../axiosInit";

export type MainnetMetricsType = {
  total_circulating_supply: number;
  total_locked: number;
  total_supply: number;
};

export const getMainnetMetrics = async (): Promise<MainnetMetricsType | null> => {
  try {
    const response = await restClient.get("https://socialchain.app/mainnet_supply");

    return {
      total_circulating_supply: response.data.total_circulating_supply,
      total_locked: response.data.total_locked,
      total_supply: response.data.total_supply,
    };
  } catch {
    return null;
  }
};
